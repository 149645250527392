<template>
  <div class="change-avatar">
    <template v-if="step === 1">
      <div class="page">
        <!-- <div class="logo">
          <square src="https://img.xinshu.me/resource/62b0a4b3095647ec8c3b84c2365cfd17" height="31.6%" plain/>
        </div> -->
        <div class="scroll-box" id="scroll">
          <div class="title">
            <square src="https://img.xinshu.me/resource/6f8aab7bfff84293a2914a4ece8411f5" height="31.1%" plain/>
          </div>
          <div class="content-box">
            <square class="preview-avatar" :src="previewAvatar" alt="" radius="0" plain/>
            <div class="avatar-outer-box">
              <square class="add-image" @click="uploadPic">
                <fa icon="spinner" spin v-if="uploading"/>
                <fa icon="cloud-upload" v-else/>
                <div style="font-size: 12px;">上传图片</div>
              </square>
              <div class="greet-list">
                <div class="greet-item" :key="item.id" v-for="item in avatarBorderList">
                  <square :selected="item.id === avatarBorder.id" :src="item.bg" height="100%" @click="selectAvatarBorder(item)"></square>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="btn-box page1-btn">
          <b-btn class="my-btn" variant="custom" @click="step = 2">生成我的新头像</b-btn>
        </div>
      </div>
    </template>
    <template v-if="step === 2">
      <div class="page" style="overflow-y: auto;">
        <!-- <div class="logo">
          <square src="https://img.xinshu.me/resource/62b0a4b3095647ec8c3b84c2365cfd17" height="31.6%" plain/>
        </div> -->
        <div class="title">
          <square src="https://img.xinshu.me/resource/6f8aab7bfff84293a2914a4ece8411f5" height="31.1%" plain/>
        </div>
        <div class="content-box">
          <square class="preview-avatar" :src="previewAvatar" alt="" radius="0" plain/>
        </div>
        <div class="btn-box" style="margin-top: 20px;">
          <!-- <b-btn class="my-btn" style="width: 48%;" variant="custom" @click="sharePopup = true">分享好友</b-btn> -->
          <b-btn class="my-btn" variant="custom" @click="savePopup = true">下载头像</b-btn>
        </div>
        <div class="recommend" @click="jumpArticle">
          <square src="https://img.xinshu.me/resource/d18b33588df74066905361b3607d5372" alt="" radius="0" plain height="34.3%"/>
        </div>
        <popup v-if="sharePopup" guide="保存图片并分享给好友" :src="sharePoster"
               @close="sharePopup = false"/>
        <popup v-if="savePopup" guide="保存我的新头像" :src="previewAvatar" @close="savePopup = false"/>
      </div>
    </template>
  </div>
</template>

<script>
import { configShare } from '@/modules/wechat'
import Upload from '@/models/upload'

export default {
  name: 'changeAvatar',
  title: '头像变身',
  data() {
    return {
      step: 1,
      uploadModel: new Upload({
        minSize: '0x0'
      }),
      userAvatar: '',
      avatarBorderList: [
        {
          id: '065',
          bg: 'https://canvas.xinshu.me/generate/border.065'
        },
        {
          id: '066',
          bg: 'https://canvas.xinshu.me/generate/border.066'
        },
        {
          id: '067',
          bg: 'https://canvas.xinshu.me/generate/border.067'
        },
        {
          id: '068',
          bg: 'https://canvas.xinshu.me/generate/border.068'
        },
        {
          id: '069',
          bg: 'https://canvas.xinshu.me/generate/border.069'
        },
        {
          id: '051',
          bg: 'https://canvas.xinshu.me/generate/border.051'
        },
        {
          id: '052',
          bg: 'https://canvas.xinshu.me/generate/border.052'
        },
        {
          id: '053',
          bg: 'https://canvas.xinshu.me/generate/border.053'
        },
        {
          id: '054',
          bg: 'https://canvas.xinshu.me/generate/border.054'
        },
        {
          id: '055',
          bg: 'https://canvas.xinshu.me/generate/border.055'
        },
        {
          id: '056',
          bg: 'https://canvas.xinshu.me/generate/border.056'
        },
        {
          id: '057',
          bg: 'https://canvas.xinshu.me/generate/border.057'
        },
        {
          id: '058',
          bg: 'https://canvas.xinshu.me/generate/border.058'
        },
        {
          id: '059',
          bg: 'https://canvas.xinshu.me/generate/border.059'
        },
        {
          id: '060',
          bg: 'https://canvas.xinshu.me/generate/border.060'
        },
        {
          id: '061',
          bg: 'https://canvas.xinshu.me/generate/border.061'
        },
        {
          id: '062',
          bg: 'https://canvas.xinshu.me/generate/border.062'
        },
        {
          id: '063',
          bg: 'https://canvas.xinshu.me/generate/border.063'
        },
        {
          id: '064',
          bg: 'https://canvas.xinshu.me/generate/border.064'
        },
        {
          id: '037',
          bg: 'https://canvas.xinshu.me/generate/border.037'
        },
        {
          id: '038',
          bg: 'https://canvas.xinshu.me/generate/border.038'
        },
        {
          id: '039',
          bg: 'https://canvas.xinshu.me/generate/border.039'
        },
        {
          id: '050',
          bg: 'https://canvas.xinshu.me/generate/border.050'
        },
        {
          id: '040',
          bg: 'https://canvas.xinshu.me/generate/border.040'
        },
        {
          id: '041',
          bg: 'https://canvas.xinshu.me/generate/border.041'
        },
        {
          id: '042',
          bg: 'https://canvas.xinshu.me/generate/border.042'
        },
        {
          id: '043',
          bg: 'https://canvas.xinshu.me/generate/border.043'
        },
        {
          id: '044',
          bg: 'https://canvas.xinshu.me/generate/border.044'
        },
        {
          id: '045',
          bg: 'https://canvas.xinshu.me/generate/border.045'
        },
        {
          id: '046',
          bg: 'https://canvas.xinshu.me/generate/border.046'
        },
        {
          id: '047',
          bg: 'https://canvas.xinshu.me/generate/border.047'
        },
        {
          id: '048',
          bg: 'https://canvas.xinshu.me/generate/border.048'
        },
        {
          id: '049',
          bg: 'https://canvas.xinshu.me/generate/border.049'
        },

        {
          id: '035',
          bg: 'https://canvas.xinshu.me/generate/border.035'
        },
        {
          id: '036',
          bg: 'https://canvas.xinshu.me/generate/border.036'
        },
        {
          id: '033',
          bg: 'https://canvas.xinshu.me/generate/border.033'
        },
        {
          id: '034',
          bg: 'https://canvas.xinshu.me/generate/border.034'
        },
        {
          id: '013',
          bg: 'https://canvas.xinshu.me/generate/border.013'
        },
        {
          id: '014',
          bg: 'https://canvas.xinshu.me/generate/border.014'
        },
        {
          id: '015',
          bg: 'https://canvas.xinshu.me/generate/border.015'
        },
        {
          id: '016',
          bg: 'https://canvas.xinshu.me/generate/border.016'
        },
        {
          id: '017',
          bg: 'https://canvas.xinshu.me/generate/border.017'
        },
        {
          id: '018',
          bg: 'https://canvas.xinshu.me/generate/border.018'
        },
        {
          id: '019',
          bg: 'https://canvas.xinshu.me/generate/border.019'
        },
        {
          id: '020',
          bg: 'https://canvas.xinshu.me/generate/border.020'
        },
        {
          id: '021',
          bg: 'https://canvas.xinshu.me/generate/border.021'
        },
        {
          id: '022',
          bg: 'https://canvas.xinshu.me/generate/border.022'
        },
        {
          id: '023',
          bg: 'https://canvas.xinshu.me/generate/border.023'
        },
        {
          id: '024',
          bg: 'https://canvas.xinshu.me/generate/border.024'
        },
        {
          id: '025',
          bg: 'https://canvas.xinshu.me/generate/border.025'
        },
        {
          id: '026',
          bg: 'https://canvas.xinshu.me/generate/border.026'
        },
        {
          id: '027',
          bg: 'https://canvas.xinshu.me/generate/border.027'
        },
        {
          id: '028',
          bg: 'https://canvas.xinshu.me/generate/border.028'
        },
        {
          id: '029',
          bg: 'https://canvas.xinshu.me/generate/border.029'
        },
        {
          id: '030',
          bg: 'https://canvas.xinshu.me/generate/border.030'
        },
        {
          id: '031',
          bg: 'https://canvas.xinshu.me/generate/border.031'
        },
        {
          id: '032',
          bg: 'https://canvas.xinshu.me/generate/border.032'
        }
      ],
      avatarBorder: {},
      sharePopup: false,
      savePopup: false
    }
  },
  computed: {
    previewAvatar() {
      if (this.avatarBorder.id) {
        return this.avatarBorder.bg + this.serialize({
          pic: this.userAvatar
        })
      } else {
        return this.userAvatar
      }
    },
    uploading() {
      return this.uploadModel.uploading
    },
    sharePoster() {
      return 'https://canvas.xinshu.me/generate/personal-national' + this.serialize({
        pic: this.previewAvatar
      })
    }
  },
  mounted() {
    this.avatarBorder = this.avatarBorderList[0]
    this.$ajax.fetchUserInfo().then(user => {
      this.userAvatar = user.avatar
    })
    configShare({
      title: '头像变身',
      desc: '给你的头像过个节吧',
      link: location.href,
      imgUrl: 'https://img.xinshu.me/resource/c196003edc9c49198fb19b8fbced1e69'
    })
    const ele = document.getElementById('scroll')
    // 判断元素是否出现了滚动条
    if (ele.scrollHeight > ele.clientHeight) {
      // 设置滚动条到最底部
      ele.scrollTop = ele.scrollHeight
    }
  },
  methods: {
    selectAvatarBorder(item) {
      this.avatarBorder = item
    },
    jumpArticle() {
      window.open('https://weixinshu.com/u/KwPKYJ')
    },
    async uploadPic() {
      if (this.uploading) {
        return
      }
      const result = await this.uploadModel.upload()
      if (result) {
        this.userAvatar = result.url
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$primaryColor: #F8CE66;
$textColor: #E24E39;

.change-avatar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  max-width: 560px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .2);
  background-image: url('https://img.xinshu.me/resource/ca5e3186c2d049d696f2c5c5bcf55b08');
  background-size: 100% auto;
  background-repeat: no-repeat;
  padding: 20px;

  .add-image {
    background-color: $textColor;
    color: #fff;
    min-width: 52px;
    width: 52px;
    position: absolute;
  }

  .content-box {
    overflow: hidden;
    width: 85%;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    margin-top: 30px;
    padding: 0;

    .preview-avatar {
      width: 70%;
      margin: 45px auto;
      box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    }

    .avatar-outer-box {
      position: relative;
      background-color: #fffafa;
      padding: 15px 20px 15px 20px;
      border-radius: 0 0 8px 8px;

      .square.selected {
        border: 2px solid $textColor;
      }

      ::v-deep .square.selected .check-icon {
        background-color: $textColor;
      }

      .greet-list {
        margin-left: 60px;
        display: flex;
        overflow-x: auto;

        .greet-item {
          min-width: 60px;
          width: 60px;
          padding: 0 4px 0 4px;
        }
      }
      .greet-list::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .btn-box {
    width: 85%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .my-btn {
      width: 100%;
      height: 42px;
      background-color: $primaryColor;
      color: $textColor;
      font-size: 16px;
      padding: 8px 0;
      border: none;
    }
  }

  .page {
    position: relative;
    height: 100%;

    .logo {
      width: 24%;
    }

    .title {
      width: 85%;
      margin: 0 auto;
      margin-top: 20px;
    }

    .page1-btn {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 30px;
    }

    .scroll-box {
      max-height: calc(100% - 122px);
      margin-top: 20px;
      overflow-y: auto;
    }

    .recommend {
      width: 85%;
      margin: 20px auto;
    }
  }

}

</style>
