<template>
  <div class="color-blindness">
    <template v-if="step === 1">
      <div class="page-1">
        <div class="title">
          <img src="https://img.xinshu.me/resource/11c7ef9d718a4fa88f16023e676c3eb5" alt="颜色大比拼">
        </div>
        <div class="intro">
          <div>测测你的眼睛对色差的辨识度</div>
          <div>找出所有色块里颜色不同的一个</div>
        </div>
        <div class="game-btns">
          <div class="game-btn" @click="startGame">
            <img src="https://img.xinshu.me/upload/30179492255d4cfbaba67a4cfbd1cfdd" alt="开始游戏">
          </div>
          <div class="game-btn" @click="goRank">
            <img src="https://img.xinshu.me/upload/0e32690f4b85406cb34dd6257482cc88" alt="排行榜">
          </div>
        </div>
        <popup v-if="rankPopup" @close="rankPopup = false">
          <b-card>
            <h5>长按下方二维码关注公众号</h5>
            <img src="https://img.xinshu.me/resource/641bc668199d4502bf103618e30f1327" style="max-width: 200px;" alt="">
            <div>关注后即可查看排行榜</div>
          </b-card>
        </popup>
      </div>
    </template>
    <template v-else-if="step === 2">
      <div class="mask" v-if="showPause">
        <div class="pause-modal">
          <div>
            <div class="ti">你当前在第{{level}}关</div>
            <div class="ti">点击下方按钮继续闯关</div>
          </div>
          <div class="cont" @click="continueGame">
            <img src="https://img.xinshu.me/upload/2d1a3fc9b0784821afe5eba2546edc71" alt="继续游戏">
          </div>
          <b-link @click="restartGame">重新开始</b-link>
        </div>
      </div>
      <div class="page-2">
        <div class="check-box">
          <div class="level">关卡{{level}}</div>
          <div class="pause-icon" @click="pauseGame">
            <img src="https://img.xinshu.me/upload/69e6402b7d7949d3ba9c9f21ba4ce2cd" alt="">
          </div>
        </div>
        <div class="countdown">{{time}}</div>
        <div class="color-outer">
          <div class="color-box">
            <div class="color-block"
                 :style="{'width': item.blockWidth + '%'}"
                 v-for="(item, idx) in colorList"
                 :key="idx"
                 @click="clickColor(item.blockType)"
            >
              <div class="block-inner" :style="{'background-color': '#' + item.blockColor}"></div>
            </div>
          </div>
        </div>
        <div class="mt-3 text-center">当前分数：{{finalScore}}</div>
      </div>
    </template>
    <template v-else-if="step === 3">
      <div class="page-3 text-center">
        <div>
          <div class="text">你玩到了第 {{level}} 关</div>
          <div class="text">本次得分</div>
        </div>
        <div class="score">{{finalScore}}</div>
        <div>
          <div class="text mb-3">你的色差辨识度</div>
          <div class="rate mx-auto">
            <img src="https://img.xinshu.me/upload/d929b84d99da431b8c9251bb3392f2b4" alt="">
            <div class="rate-text">{{rate}}</div>
          </div>
        </div>
        <div v-if="showTicket" class="ticket" @click="gzhPopup = true">
          <img src="https://img.xinshu.me/upload/7ba9c016a0784b6ea77bb0ccd31d4ea9" alt="ads">
        </div>
        <div class="game-btns">
          <div class="game-btn" @click="step = 1">
            <img src="https://img.xinshu.me/upload/7c9ef10689b948518cf8786ff44b8f75" alt="再玩一次">
          </div>
          <div class="game-btn" @click="sharePopup = true">
            <img src="https://img.xinshu.me/upload/2acb4edc338140d6951c5d8cec2d6adc" alt="分享好友">
          </div>
        </div>
        <popup v-if="sharePopup" guide="保存图片并分享给好友" :src="sharePoster"
               @close="sharePopup = false"/>
        <popup v-if="gzhPopup" src="https://img.xinshu.me/resource/ea189603bd354e86b1f256c2e9536261"
               @close="gzhPopup = false"/>
      </div>
    </template>
    <template v-else-if="step === 4">
      <div class="page-4">
        <div class="rank-ti">排行榜</div>
        <div class="back" @click="step = 1">
          <fa icon="angle-left"/>
          返回
        </div>
        <loading v-if="rankLoading"/>
        <div class="rank">
          <div class="rank-item"
               :class="{'highlight': item.uid === user.openid}"
               v-for="(item, idx) in rankList" :key="item.id">
            <div v-if="idx === 0" class="num-box">
              <img src="https://img.xinshu.me/resource/89d8960ecdf94ca5b8cc3cc73679adab" alt="第一名">
            </div>
            <div v-else-if="idx === 1" class="num-box">
              <img src="https://img.xinshu.me/resource/1c003314aff74e39a02905b9b2c2adf8" alt="第二名">
            </div>
            <div v-else-if="idx === 2" class="num-box">
              <img src="https://img.xinshu.me/resource/61283ff2185241618be61b60d033d1dd" alt="第三名">
            </div>
            <div v-else class="num-box">
              <div class="num">{{idx + 1}}</div>
            </div>
            <div class="user-info">
              <avatar :src="item.avatar" bordered size="2.5em" class="mr-2"/>
              {{item.nickname}}
            </div>
            <div class="score">{{item.score}}</div>
          </div>
          <div v-if="myRank.id" class="rank-item highlight">
            <div class="num-box">
              <div class="num">{{myRank.position + 1}}</div>
            </div>
            <div class="user-info">
              <avatar :src="myRank.avatar" bordered size="2.5em" class="mr-2"/>
              {{myRank.nickname}}
            </div>
            <div class="score">{{myRank.score}}</div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { configShare } from '@/modules/wechat'

export default {
  name: 'ColorBlindness',
  title: '你是色盲吗',
  data() {
    return {
      gameId: 'color-blindness', // TODO 游戏名，请修改此值
      step: 1,

      level: 1,
      time: 60,
      offset: 0,
      blocks: 0,
      wrongTimes: 0,

      colorList: [],
      playing: false,
      timer: null,
      showPause: false,
      sharePopup: false,
      rankList: [],
      myRank: {},
      rankLoading: false,
      onrank: false,
      gzhPopup: false,
      rankPopup: false
    }
  },
  computed: {
    sharePoster() {
      return 'https://canvas.xinshu.me/generate/7y-color' + this.serialize({
        avatar: this.user.avatar,
        nickname: this.user.nickname,
        no: this.finalScore,
        ch: this.rate
      })
    },
    showTicket() {
      return !this.user.hasBook
    },
    finalScore() {
      // 游戏得分
      return Math.max(Math.round(Math.pow(this.blocks, 2) / this.offset * 100) - this.wrongTimes - 2, 0)
    },
    rate() {
      const rates = [
        [32, '对色差具有超凡能力'],
        [30, '超过大部分人的水平'],
        [27, '优秀！火眼金睛'],
        [25, '达到了正常人的水平'],
        [23, '低于大部分人的水平'],
        [19, '相当于鼴鼠的视力'],
        [0, '基本上是瞎子']
      ]
      for (const [minLv, rate] of rates) {
        if (this.level > minLv) {
          return rate
        }
      }
      return rates[rates.length - 1]
    }
  },
  mounted() {
    configShare({
      title: '色块小游戏',
      desc: '测测你的色差辨识度',
      link: location.href,
      imgUrl: 'https://img.xinshu.me/resource/ef51e4cdff2148a799f6cb2341ea447c'
    })
  },
  methods: {
    async getLeaderboard() {
      try {
        const uid = this.user.openid
        this.rankLoading = true
        this.rankList = await this.$req.get('/api/leaderboard', {
          baseURL: 'https://ark.xinshu.me',
          params: {
            game: this.gameId,
            page: 1,
            pageSize: 50
          }
        })
        for (let i = 0; i < this.rankList.length; i++) {
          const ri = this.rankList[i]
          if (ri.uid === uid) {
            this.onrank = true
            break
          }
        }
        if (!this.onrank) {
          this.myRank = await this.$req.get(`/api/leaderboard/color-blindness/${uid}`, {
            baseURL: 'https://ark.xinshu.me'
          })
        }
      } finally {
        this.rankLoading = false
      }
    },
    uploadScore() {
      return this.$req.post('/api/leaderboard', {
        game: this.gameId, // 游戏名，用来查询排行榜
        uid: this.user.openid, // 用户ID，用来查询自己的排名
        score: this.finalScore, // 分数
        avatar: this.user.avatar, // 用户头像，排行榜显示用
        nickname: this.user.nickname, // 用户昵称，排行榜显示用
        meta: {level: this.level, wrongTimes: this.wrongTimes} // 其他数据，可自定义
      }, {baseURL: 'https://ark.xinshu.me'})
    },
    goRank() {
      if (!this.user.subscribe) {
        this.rankPopup = true
        return
      }
      this.step = 4
      this.getLeaderboard()
    },
    startGame() {
      this.step = 2
      this.initGame()
    },
    initGame() {
      this.showPause = false
      this.time = 60
      this.level = 1
      this.onrank = false
      this.wrongTimes = 0
      this.countdown()
      this.genBlocks()
      this.playing = true
    },
    pauseGame() {
      this.showPause = true
      this.playing = false
      clearInterval(this.timer)
    },
    continueGame() {
      this.showPause = false
      this.playing = true
      this.countdown()
    },
    async restartGame() {
      const confirmed = await this.$dialog.confirm('要重新开始游戏吗？')
      if (confirmed) {
        this.step = 1
        clearInterval(this.timer)
      }
    },
    finishGameHook() {
      this.playing = false
      this.step = 3
      this.uploadScore()
      if (this.showTicket) {
        this.$req.get('/api/user/activity_coupons/E05SWN')
      }
    },
    countdown() {
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        if (this.time <= 0) {
          clearInterval(this.timer)
          this.time = 0
          this.finishGameHook()
        } else {
          this.time--
        }
      }, 1000)
    },
    clickColor(val) {
      if (!this.playing) {
        return
      }
      if (val === 'normal') {
        this.wrongTimes++
        return
      }
      this.level++
      this.genBlocks()
    },
    // 根据关卡等级返回相应的一般颜色和特殊颜色
    getColor(cp) {
      // rgb 随机加减 random
      const offset = Math.floor(200 / cp)
      this.offset = 200 / cp

      // 获取随机一般颜色，拆分三色值
      const color = this.randomColor(17, 255)
      const m = color.match(/[\da-z]{2}/g)

      // 转化为 10 进制
      for (let i = 0; i < m.length; i++) {
        m[i] = parseInt(m[i], 16) // rgb
      }
      const specialColor =
        this.getRandomColorNumber(m[0], offset) +
        this.getRandomColorNumber(m[1], offset) +
        this.getRandomColorNumber(m[2], offset)

      return [color, specialColor]
    },
    // 获取随机颜色相近的 rgb 三色值
    getRandomColorNumber(num, random) {
      const temp = Math.floor(num + (Math.random() < 0.5 ? -1 : 1) * random)
      if (temp > 255) {
        return 'ff'
      } else if (temp > 16) {
        return temp.toString(16)
      } else if (temp > 0) {
        return '0' + temp.toString(16)
      } else {
        return '00'
      }
    },
    // 随机颜色
    randomColor(min, max) {
      const r = this.randomNum(min, max).toString(16)
      const g = this.randomNum(min, max).toString(16)
      const b = this.randomNum(min, max).toString(16)
      return r + g + b
    },
    // 随机数
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min)
    },
    // 下一关
    genBlocks() {
      let col // 列数
      // 设置列数，最高不超过16
      if (this.level === 1) {
        col = 2
      } else if (this.level >= 2 && this.level <= 3) {
        col = 3
      } else if (this.level >= 4 && this.level <= 6) {
        col = 4
      } else if (this.level >= 7 && this.level <= 10) {
        col = 5
      } else if (this.level >= 11 && this.level <= 15) {
        col = 6
      } else if (this.level >= 16 && this.level <= 21) {
        col = 7
      } else if (this.level >= 22 && this.level <= 28) {
        col = 8
      } else if (this.level >= 29 && this.level <= 36) {
        col = 9
      } else if (this.level >= 37 && this.level <= 45) {
        col = 10
      } else if (this.level >= 46 && this.level <= 54) {
        col = 12
      } else if (this.level >= 55) {
        col = 16
      }

      this.blocks = col

      // 小方块宽度
      const blockWidth = ((100 / col).toFixed(2) * 100 - 1) / 100

      // 随机方块index
      const randomBlock = Math.floor(col * col * Math.random())

      // 解构赋值获取一般颜色和特殊颜色
      const [normalColor, specialColor] = this.getColor(this.level)

      // 方块元素
      const item = {
        blockWidth,
        blockColor: normalColor,
        blockType: 'normal'
      }

      // 所有方块的数组
      this.colorList = []

      // 初始化数组
      for (let i = 0; i < col * col; i++) {
        this.colorList.push(item)
      }

      // 随机修改特殊的
      this.colorList.splice(randomBlock, 1, {
        blockWidth,
        blockColor: specialColor,
        blockType: 'special'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$primaryColor: #30A36B;

.color-blindness {
  touch-action: manipulation;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  max-width: 480px;
  padding: 0;
  background-color: rgba(224, 235, 224, 1);
  @include bgi('https://img.xinshu.me/upload/4364dde5d53745a6aed87f87494081b7');
  background-size: 100% 100%;
  background-position: top center;
  background-repeat: no-repeat;

  .page-1 {
    width: 100%;
    height: 100%;
    padding: 12vh 3vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;

    .intro {
      height: 90px;
      line-height: 2;
      text-align: center;
      color: $primaryColor;
    }

    .game-btns {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .game-btn {
        width: 60%;
        cursor: pointer;
      }

      .game-btn:not(:first-child) {
        margin-top: 2vh;
      }
    }
  }

  .mask {
    max-width: 480px;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, .6);
    z-index: 100;
    overflow: hidden;
    visibility: visible;

    .pause-modal {
      background-color: #F5F4EC;
      border-radius: 12px;
      width: 90%;
      position: absolute;
      height: 26vh;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      padding: 8% 0;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;

      .ti {
        color: $primaryColor;
        text-align: center;
        font-size: 2.2vh;
      }

      .cont {
        width: 60%;
        cursor: pointer;
      }
    }
  }

  .page-2 {
    height: 100%;
    padding: 5vh 2vh;

    .check-box {
      position: relative;
      width: 100%;
      height: 5vh;
      display: flex;
      justify-content: center;
      align-items: center;

      .level {
        width: 30%;
        text-align: center;
        border-radius: 50rem;
        background-color: #D0E3DD;
        color: $primaryColor;
        font-size: 2.4vh;
      }

      .pause-icon {
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;

        img {
          height: 100%;
        }
      }
    }

    .countdown {
      height: 15vh;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 6vh;
      color: $primaryColor;
    }

    .color-outer {
      position: relative;
      padding-top: 100%;
      background-color: #F5F4EC;
      border-radius: 8px;

      .color-box {
        position: absolute;
        width: 96%;
        height: 96%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;

        .color-block {
          float: left;
          box-sizing: border-box;
          padding: 0.5%;

          .block-inner {
            content: '';
            display: block;
            width: 100%;
            padding-top: 100%;
            border-radius: 4px;
            user-select: none;
          }
        }
      }
    }
  }

  .page-3 {
    height: 100%;
    padding: 12vh 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: $primaryColor;

    .text {
      font-size: 2.2vh;
    }

    .score {
      font-size: 7vh;
    }

    .rate {
      width: 80%;
      position: relative;

      .rate-text {
        display: flex;
        height: 75%;
        position: absolute;
        top: 0;
        width: 100%;
        font-size: 3vh;
        color: #fff;
        align-items: center;
        justify-content: center;
      }
    }

    .ticket {
      width: 70%;
      cursor: pointer;
    }

    .game-btns {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 3vh;

      .game-btn {
        width: 60%;
        cursor: pointer;
      }

      .game-btn:not(:first-child) {
        margin-top: 2vh;
      }
    }
  }

  .page-4 {
    position: relative;
    height: 100%;
    padding: 2vh 14px 5vh 14px;

    .back {
      position: absolute;
      top: calc(2vh + 6px);
      height: 3em;
      line-height: 3em;
      left: 2em;
      cursor: pointer;
      color: $primaryColor;
    }

    .rank-ti {
      height: 3em;
      line-height: 3em;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
    }

    .rank {
      height: calc(100% - 4em);
      overflow-y: auto;
      padding: 0 12px 0 12px;

      .rank-item {
        padding-right: 10px;
        height: 3em;
        display: flex;
        align-items: center;
        margin: 1rem 0;
        border-radius: 8px;
        box-shadow: 0 0 5px 5px rgba(255, 255, 255, .3);

        .num-box {
          min-width: 5em;
          text-align: center;

          img {
            height: 2.5em;
          }

          .num {
            font-weight: bold;
            font-size: 16px;
          }
        }

        .user-info {
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .score {
          margin-left: 1em;
          font-weight: bold;
          text-align: right;
          font-size: 1.25em;
        }
      }

      .highlight {
        box-shadow: 0 0 5px 5px rgba(48, 163, 107, .5);
      }
    }
  }
}
</style>
