<template>
  <div class="collection-2022">
    <div v-show="step === 1" class="home">
      <div class="rule" @click="showRules">活动规则</div>
      <div class="head">
        <square src="https://img.xinshu.me/resource/620dc8c5009a474aaa6c5fd3223b94b6" height="56%"
                radius="0"/>
      </div>
      <div class="content">
        <div v-if="selfItem" class="collection-card" style="margin-bottom: 14px;">
          <div class="shaidan-info">
            <div class="avatar">
              <square :src="selfItem.avatar" height="100%" border radius="6px"/>
            </div>
            <div class="info">
              <div class="nickname">{{selfItem.nickname}}</div>
              <div class="flag">
                <span>{{selfItem.years}}年</span>
                <span style="margin-left: 5px;">做书{{selfItem.books}}本</span>
              </div>
            </div>
            <div class="delete" @click="deleteSelf()">删除</div>
          </div>
          <div class="shaidan-text">
            朋友圈一键成书，我已坚持{{selfItem.years}}年，做了{{selfItem.books}}本微信书。{{selfItem.text}}
          </div>
          <div class="shaidan-pic">
            <div class="img-box">
              <square class="img-item" v-for="(pic, idx) in selfItem.pics" :key="idx" :src="pic"
                      height="100%" radius="6px"
                      @click="$previewImage({url: pic, urls: selfItem.pics})"/>
            </div>
          </div>
        </div>
        <template v-if="!items.length">
          <div class="collection-card" v-for="i in 3" :key="i">
            <div class="shaidan-info">
              <div class="avatar">
                <ske type="square"></ske>
              </div>
              <div class="info">
                <div class="nickname">
                  <ske width="6em"></ske>
                </div>
                <div class="flag">
                  <ske width="3em"></ske>
                  <ske width="2em"></ske>
                </div>
              </div>
            </div>
            <div class="shaidan-text">
              <ske width="80%" block></ske>
              <ske block></ske>
              <ske block></ske>
              <ske block></ske>
            </div>
          </div>
        </template>
        <div class="collection-card" v-for="item in limitedItems" :key="item.objectId">
          <div class="shaidan-info">
            <div class="avatar">
              <square :src="item.avatar" height="100%" border radius="6px"/>
            </div>
            <div class="info">
              <div class="nickname">{{item.nickname}}</div>
              <div class="flag">
                <span>{{item.years}}年</span>
                <span style="margin-left: 5px;">做书{{item.books}}本</span>
              </div>
            </div>
          </div>
          <div class="shaidan-text">朋友圈一键成书，我已坚持{{item.years}}年，做了{{item.books}}本微信书。{{item.text}}</div>
          <div class="shaidan-pic">
            <div class="img-box">
              <square class="img-item" v-for="(pic, idx) in item.pics" :key="idx"
                      :src="$img(pic, 320)" height="100%" radius="6px"
                      @click="$previewImage({url: pic, urls: item.pics})"/>
            </div>
          </div>
        </div>

        <div class="btn-area" v-if="limit < items.length">
          <b-btn block @click="limit += 50" variant="link">查看更多</b-btn>
        </div>
      </div>
      <div v-show="loading === false" class="tail">
        <!-- <div :class="{'grey-btn': selfItem}" class="pmy-btn" style="width: 94%; font-size: 16px;"
             @click="goStep2">
          {{selfItem ? '您已晒单' : '我要晒书'}}
        </div> -->
        <div class="pmy-btn grey-btn" style="width: 94%; font-size: 16px;">活动已结束</div>
      </div>
    </div>
    <div v-show="step === 2" class="shaidan">
      <div class="collection-card">
        <div class="describe">
          1. 请你找出所有做过的微信书，拍摄至少1张「微信书全家福」，照片可参考下图
        </div>
        <div class="img-box">
          <square class="img-item" v-for="(pic, idx) in describeImg"
                  :key="idx" :src="$img(pic, 240)" height="100%" radius="6px"
                  @click="$previewImage({url: pic, urls: describeImg})"/>
        </div>
      </div>
      <div class="collection-card" style="margin-top: 14px;">
        <div class="describe">
          2. 将「微信书全家福」发送到自己的朋友圈，并配上我们为你提供的专属文案
        </div>
        <div class="own-text" v-show="newItem.years !== '' && newItem.books !== ''"
             v-html="ownText"></div>
        <div v-if="newItem.years === ''" class="pmy-btn" @click="showMask = true">点击生成专属文案</div>
        <div v-else class="btn-line">
          <div class="plain-btn" style="width: calc(50% - 6px);" @click="showMask = true">修改文案</div>
          <div class="pmy-btn" style="width: calc(50% - 6px);" @click="clickCopy">复制文案</div>
        </div>
      </div>
      <div class="collection-card" style="margin-top: 14px;">
        <div class="describe">
          3. 将发送的朋友圈截图，并配上「微信书全家福」，工作人员将会在7个工作日内向您发放相应的「微信书」无门槛优惠券（每本送5元，最高送200）
        </div>
        <div class="pmy-btn" @click="beforeCommit">提交审核</div>
      </div>
      <b-btn style="margin-top: 10px; color: #EAB854;" variant="link" @click="step = 1" block>返回首页
      </b-btn>
    </div>
    <transition name="fade">
      <div v-if="showMask" class="collection-mask" @click.self="closeQuestion">
        <div class="collection-card" @click.stop>
          <div class="question">这是您做微信书的第几年？</div>
          <b-form-group>
            <b-select v-model="newItem.years" number>
              <option value="">请选择年数</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
            </b-select>
          </b-form-group>

          <div class="question">您一共做了多少本微信书？</div>
          <b-form-group>
            <b-input-group append="本">
              <b-input placeholder="请输入数量" @change="newItem.books = Math.abs(newItem.books)"
                       type="number" min="0" number
                       v-model="newItem.books"/>
            </b-input-group>
          </b-form-group>

          <div class="question">做了这么多书，您有什么想说的？</div>
          <b-form-group>
            <textarea class="form-control" placeholder="记录点滴，留住美好，生活原来如此有滋有味"
                      rows="3" v-model="newItem.text"></textarea>
          </b-form-group>
          <b-btn :disabled="!canCreate" block
                 style="background: #EAB854; border: none; color: #fff;" @click="createText">确认生成
          </b-btn>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div v-if="showCommit" class="collection-mask" @click.self="showCommit = false">
        <div class="collection-card" style="padding-top: 20px; padding-bottom: 20px;">
          <div class="question">1. 请上传至少1张「微信书全家福」照片</div>
          <upload-zone style="margin-bottom: 14px;" min-size="320x320" max="8" hideExceeded
                       v-model="wxsPics"></upload-zone>
          <div class="question">2. 请上传1张朋友圈截图</div>
          <upload-zone style="margin-bottom: 14px;" min-size="320x320" max="1" hideExceeded
                       v-model="pyqPics"></upload-zone>
          <b-btn :disabled="!canSubmit || commiting" block
                 style="background: #EAB854; border: none; color: #fff;" @click="clickSubmit">
            {{commiting ? '正在提交' : '提交审核'}}
          </b-btn>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div v-if="showAudit" class="collection-mask" @click.self="closeAudit">
        <div class="collection-card" style="padding-top: 20px; padding-bottom: 20px;">
          <div
            style="display: flex; flex-direction: column; align-items: center; margin-bottom: 14px;">
            <div style="font-weight: bold; font-size: 16px; text-align: justify;">
              您的审核已经提交，工作人员将会在7个工作日内向您发放相应的「微信书」无门槛优惠券，添加下方客服咨询发放进度
            </div>
            <div style="width: 50%; margin: 14px 0;">
              <square src="https://img.xinshu.me/resource/e4c37d9443a043a996a0f0d0887290ac"
                      height="100%"/>
            </div>
            <div>长按识别二维码</div>
          </div>
          <b-btn block style="background: #EAB854; border: none; color: #fff;" @click="closeAudit">
            关闭
          </b-btn>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import copyText from 'clipboard-copy'
import UploadZone from '../../components/UploadZone'
import lean from '../../models/leancloud'
import preview from '@/mixins/preview-image'

export default {
  name: 'collection2022',
  title: '晒出你的微信书',
  components: {UploadZone},
  mixins: [preview],
  data() {
    return {
      step: 1,
      describeImg: [
        'https://img.xinshu.me/resource/d37188c5ecac41608f16e3156275a21e',
        'https://img.xinshu.me/resource/0a7492aaae50459cb76d9c145478b2dc',
        'https://img.xinshu.me/resource/ce4ce8af45e34fae9cffd68c8f043445'
      ],
      showMask: false,
      newItem: {
        years: '',
        books: '',
        text: ''
      },
      showCommit: false,
      pyqPics: [],
      wxsPics: [],
      selfItem: null,
      commiting: false,
      loading: false,
      items: [],
      showAudit: false,
      limit: 50
    }
  },
  computed: {
    limitedItems() {
      return this.items.slice(0, this.limit)
    },
    canCreate() {
      return this.newItem.years && this.newItem.books
    },
    canSubmit() {
      return this.wxsPics.length && Object.keys(this.pyqPics).length
    },
    ownText() {
      return `#公众号：心书<br>
              朋友圈一键成书，我已坚持${this.newItem.years}年，做了${this.newItem.books}本微信书。${this.newItem.text}
              `
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.loading = true
      this.items = await this.fetchItems()
      await this.fetchSelf().then(result => {
        this.selfItem = result[0]
      })
      this.items = this.items.filter(item => item.openid !== this.user.openid)
      this.loading = false
    },
    closeAudit() {
      this.showAudit = false
      if (this.step !== 1) {
        this.step = 1
      }
    },
    closeQuestion() {
      this.newItem = {
        years: '',
        books: '',
        text: ''
      }
      this.showMask = false
    },
    createText() {
      this.showMask = false
      if (this.newItem.text === '') {
        this.newItem.text = '记录点滴，留住美好，生活原来如此有滋有味。'
      }
    },
    goStep2() {
      this.newItem = {
        years: '',
        books: '',
        text: ''
      }
      this.pyqPics = []
      this.wxsPics = []
      if (this.selfItem) {
        this.showAudit = true
        return
      }
      this.step = 2
    },
    clickCopy() {
      let text = this.ownText.replace(/<br>/g, '')
      text = text.replace(/ /g, '')
      copyText(text).then(() => {
        this.$alert.success('文案已复制')
      }).catch(() => {
        this.$alert.error('复制失败，请手动复制文案')
      })
    },
    beforeCommit() {
      if (this.newItem.years === '') {
        this.$alert.warn('请先生成专属文案')
        return
      }
      this.showCommit = true
    },
    async clickSubmit() {
      try {
        this.wxsPics = this.wxsPics.map(i => i.url)
        const pics = this.wxsPics.concat()
        pics.push(this.pyqPics.url)
        const data = Object.assign({
          avatar: this.user.avatar,
          openid: this.user.openid,
          nickname: this.user.nickname
        }, this.newItem)
        data.books = Number(data.books)
        data.years = Number(data.years)
        data.pics = pics
        this.commiting = true
        const res = await lean.post('/classes/Collection2022', data)
        this.selfItem = data
        this.selfItem.objectId = res.objectId
        this.$alert.success('发布成功')
        this.showCommit = false
        this.showAudit = true
      } finally {
        this.commiting = false
      }
    },
    fetchItems() {
      return lean.get('/classes/Collection2022?where={"hidden":false}&order=-createdAt&limit=1000')
    },
    fetchSelf() {
      return lean.get(`/classes/Collection2022?where={"openid":"${this.user.openid}"}&order=-createdAt&limit=1`)
    },
    async deleteSelf() {
      const confirmed = await this.$dialog.confirm({
        title: '删除晒单',
        content: '是否要删除自己的晒单？'
      })
      if (!confirmed) {
        return
      }
      return lean.delete('/classes/Collection2022/' + this.selfItem.objectId).then(() => {
        this.$alert.success('删除成功')
        this.selfItem = null
      })
    },
    showRules() {
      this.$dialog.confirm({
        title: '活动规则',
        content: [
          '拍「微信书全家福」照片，晒一下朋友圈，截图给我们就可以获得微信书本数×5元的「微信书」无门槛优惠券：',
          '1. 找出曾经做过的微信书，拍摄至少1张「微信书全家福」照片；',
          '2. 将照片公开发送到朋友圈，并配上我们为您提供的专属文案；',
          '3. 将发送的朋友圈截图，并配上「微信书全家福」，工作人员将会在7个工作日内向您发放相应的「微信书」无门槛优惠券（每本送5元，最高送200）；',
          '4. 微信书无门槛优惠券有效期2个月。'
        ].join('<br>'),
        okTitle: '我知道了',
        okOnly: true
      })
    }
  }
}
</script>

<style lang="scss">
body[data-page="collection2022"] {
  main {
    padding-bottom: 0;
  }
}
</style>

<style lang="scss" scoped>
$theme: #EAB854;

.collection-2022 {
  max-width: 480px;
  // min-height: 100vh;
  padding: 0;
  // margin-bottom: 60px;
  background-color: #fff;

  .home {
    position: relative;
  }

  .rule {
    width: 70px;
    height: 28px;
    position: absolute;
    right: 0;
    top: 40px;
    font-size: 12px;
    color: #fff;
    background-color: $theme;
    border-radius: 28px 0 0 28px;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .content {
    position: relative;
    width: 100%;
    margin-top: -14px;
    padding: 0 14px;
    padding-bottom: 14px;
    margin-bottom: 60px;

    .shaidan-info {
      display: flex;
      position: relative;

      .avatar {
        width: 12%;
      }

      .info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 14px;

        .nickname {
          font-weight: bold;
        }

        .flag {
          font-size: 12px;
          color: rgba(131, 131, 131);
        }
      }

      .delete {
        position: absolute;
        right: 0;
        color: $theme;
        cursor: pointer;
      }
    }

    .shaidan-text {
      margin: 10px 0;
      text-align: justify;
    }
  }

  .tail {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    height: 60px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 8px rgba(0, 0, 0, .2);
  }

  .pmy-btn {
    height: 45px;
    border-radius: 8px;
    background-color: $theme;
    max-width: 460px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    cursor: pointer;
  }

  .grey-btn {
    background-color: #d5d5d5;
  }

  .shaidan {
    width: 100%;
    max-width: 480px;
    min-height: 100vh;
    background-color: #fff;
    padding: 20px 14px 20px 14px;

    .describe {
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 14px;
      text-align: justify;
    }

    .own-text {
      background-color: rgba(238, 238, 238, 1);
      padding: 8px;
      margin-bottom: 14px;
      line-height: 1.6;
      text-align: justify;
    }

    .btn-line {
      display: flex;
      justify-content: space-between;
    }

    .plain-btn {
      height: 45px;
      border-radius: 8px;
      background-color: #fff;
      border: 1px solid $theme;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $theme;
      cursor: pointer;
    }
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }

  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
  {
    opacity: 0;
  }

  .collection-mask {
    width: 100%;
    max-width: 480px;
    background-color: rgba(0, 0, 0, .4);
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 99;
    padding: 0 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .question {
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 10px;
    }
  }

  .collection-card {
    padding: 14px;
    box-shadow: 0 0 8px rgba(0, 0, 0, .2);
    border-radius: 8px;
    background-color: #fff;
    margin-bottom: 1rem;

    .img-box {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;

      .img-item {
        flex: 1;
        width: calc((100% - 16px) / 3);
        min-width: calc((100% - 16px) / 3);
        max-width: calc((100% - 16px) / 3);
        margin: 0 8px 8px 0;

        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
