import axios from 'axios'

const appId = 'hjqustm0za3kmf8r6p3rrpmxrfl24ykoo6rrcwmw0ehjzw0h'
const appKey = 'ia9d0o2ldleoqudyv20d7mgreimxjy11fpkkr5klh7a8yea8'

const instance = axios.create({
  baseURL: 'https://lean.xinshu.me/1.1',
  timeout: 10000,
  headers: {
    'X-LC-Id': appId,
    'X-LC-Key': appKey
  }
})

instance.interceptors.response.handlers.push(...axios.interceptors.response.handlers)

export default instance
